import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material";

// colors
const primary = "#9461FF";
const primaryDashboard = "#5C38A8";
const primaryBackground = "#F3F0F9";
const primaryLight = "#E3DBF5";

// #BFA4F8
const secondary = "#FFC107";
// const primary = "#FEE64E";

const black = "#33383B";
const lightBlack = "#A1A8B1";
const darkBlack = "#25292C";
//new
const gray = "#787878";
const white = "#ffffff";
const background = "#f5f5f5";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "#EED534";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: primary,
        dashboard: primaryDashboard,
        background: primaryBackground,
        primaryLight: primaryLight,
      },
      secondary: { main: secondary },
      common: {
        black,
        darkBlack,
        lightBlack,
        white,
        gray,
      },
      warning: {
        light: warningLight,
        main: warningMain,
        dark: warningDark,
      },
      // Used to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      background: {
        default: background,
      },
      spacing,
    },
    breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xl,
        lg,
        md,
        sm,
        xs,
      },
    },

    border: {
      borderColor: borderColor,
      borderWidth: borderWidth,
    },
    overrides: {
      MuiExpansionPanel: {
        root: {
          position: "static",
        },
      },
      MuiTableCell: {
        root: {
          paddingLeft: spacing * 2,
          paddingRight: spacing * 2,
          borderBottom: `${borderWidth}px solid ${borderColor}`,
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing,
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: borderColor,
          height: borderWidth,
        },
      },
      MuiPrivateNotchedOutline: {
        root: {
          borderWidth: borderWidth,
        },
      },
      MuiListItem: {
        divider: {
          borderBottom: `${borderWidth}px solid ${borderColor}`,
        },
      },
      MuiDialog: {
        paper: {
          width: "100%",
          maxWidth: 430,
          marginLeft: spacing,
          marginRight: spacing,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: darkBlack,
        },
      },
      MuiExpansionPanelDetails: {
        root: {
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing,
          },
        },
      },
    },
    typography: {
      body1: {
        fontFamily: "Inter, sans-serif",
      },
      bodyDashboard: {
        fontFamily: "Inter, sans-serif",
        color: primaryDashboard,
      },
      useNextVariants: true,
      fontFamily: "Poppins, sans-serif",
    },
  })
);

export default responsiveFontSizes(theme);
